import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import type React from 'react'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CoinsEuroIcon from '../../../../../../assets/icons/coins-euro-stroke-rounded'
import {
  useGetCurrenciesQuery,
  useGetNetworksQuery,
  useGetUserQuery,
} from '../../../../../../redux/services/coreApi'
import type { RootState } from '../../../../../../redux/store'
import type {
  CurrencyDto,
  NetworkDto,
} from '../../../../../../types/coreApi-types'
import CurrencyRow from './CurrencyRow'
import Search from './Search'
import TabButton from './TabButton'
import ThemeButton from './ThemeButton'

interface CurrencyModalProps {
  isOpen: boolean
  onClose: () => void
  onClickCurrency: (currency: CurrencyDto) => void
  networksOverride?: NetworkDto[]
  currenciesOverride?: CurrencyDto[]
  lockToCrypto?: boolean
}

const CurrencyModal: React.FC<CurrencyModalProps> = ({
  isOpen,
  onClose,
  onClickCurrency,
  networksOverride,
  currenciesOverride,
  lockToCrypto,
}) => {
  const sessionUser = useSelector((state: RootState) => state.session.user)

  const [network, setNetwork] = useState<NetworkDto | null>(null)
  const [tab, setTab] = useState<'crypto' | 'fiat'>('crypto')

  const { data: networksData } = useGetNetworksQuery()
  const { data: currenciesData } = useGetCurrenciesQuery()

  const networks = networksOverride ?? networksData
  const currencies = currenciesOverride ?? currenciesData

  const { data: user } = useGetUserQuery(sessionUser.id)

  const [query, setQuery] = useState<string>('')

  const favouriteCurrencyIds = useMemo(
    () => user?.favouriteCurrencies.map(i => i.id),
    [user]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded={'24px'} maxWidth={'900px'} p="16px" pb="0px">
        <Search onChange={v => setQuery(v)} value={query} />

        {query.length === 0 && lockToCrypto !== true && (
          <div className={`flex gap-1 mt-3 transition-all`}>
            <TabButton
              title="Crypto"
              onClick={() => setTab('crypto')}
              active={tab === 'crypto'}
              icon="crypto"
            />
            <TabButton
              title="Fiat"
              onClick={() => setTab('fiat')}
              active={tab === 'fiat'}
              icon="fiat"
            />
          </div>
        )}

        {query.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-8 h-[446px] overflow-y-auto pr-3 pb-4"
          >
            <>
              {currencies
                ?.filter(el =>
                  el.name.toLowerCase().includes(query.toLowerCase())
                )
                .sort(el => (favouriteCurrencyIds?.includes(el.id) ? -1 : 1))
                .map((item, i) => {
                  return (
                    <CurrencyRow
                      key={i}
                      showSubtitle
                      currency={item}
                      isFavourite={favouriteCurrencyIds?.includes(item.id)}
                      onClick={() => {
                        onClose()
                        onClickCurrency(item)
                      }}
                    />
                  )
                })}

              {currencies?.filter(el =>
                el.name.toLowerCase().includes(query.toLowerCase())
              ).length === 0 && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="h-4/5 flex flex-col items-center justify-center mb-32"
                >
                  <CoinsEuroIcon className="text-brand-light-text-description mb-1" />

                  <p className="font-semi text-brand-light-text-default mb-1">
                    No currencies found
                  </p>
                  <p className="font-normal text-brand-light-text-description text-sm">
                    We couln't find any currencies that matched your search.
                  </p>
                </motion.div>
              )}
            </>
          </motion.div>
        )}

        {tab === 'crypto' && query.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-8 grid grid-cols-7 gap-3"
          >
            <div className="col-span-full md:col-span-2 auto-rows-min flex md:h-[400px] pb-3 md:pb-4 md:pr-3 md:grid md:grid-cols-2 gap-1.5 overflow-x-auto shrink-0 md:overflow-y-auto">
              {networks?.map((item, i) => {
                return (
                  <ThemeButton
                    key={i}
                    title={item.name}
                    subtitle={item.nativeCurrency?.isoCode}
                    onClick={() => {
                      if (network === item) setNetwork(null)
                      else setNetwork(item)
                    }}
                    iconUrl={item.iconUrl}
                    isActive={item === network}
                  />
                )
              })}
            </div>
            <div className="col-span-full md:col-span-5 h-[400px] pb-4 overflow-y-auto pr-3">
              {(network
                ? [...network?.cryptoCurrencies]
                : [...(currencies ?? [])]
              )
                .filter(el => el.currencyType === 'CryptoCurrency')
                .sort(el => (favouriteCurrencyIds?.includes(el.id) ? -1 : 1))
                .map((item, i) => {
                  return (
                    <CurrencyRow
                      key={i}
                      currency={item}
                      isFavourite={favouriteCurrencyIds?.includes(item.id)}
                      onClick={() => {
                        onClose()
                        onClickCurrency(item)
                      }}
                    />
                  )
                })}
            </div>
          </motion.div>
        )}
        {tab === 'fiat' && query.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-8 h-[400px] overflow-y-auto pr-3 pb-4"
          >
            {currencies
              ?.filter(el => el.currencyType === 'FiatCurrency')
              .sort(el => (favouriteCurrencyIds?.includes(el.id) ? -1 : 1))
              .map((item, i) => {
                return (
                  <CurrencyRow
                    key={i}
                    currency={item}
                    showSubtitle
                    isFavourite={favouriteCurrencyIds?.includes(item.id)}
                    onClick={() => {
                      onClose()
                      onClickCurrency(item)
                    }}
                  />
                )
              })}
          </motion.div>
        )}
      </ModalContent>
    </Modal>
  )
}

export default CurrencyModal
