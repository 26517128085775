import type React from 'react'
import DefaultModal from './DefaultModal'

interface TemporaryServiceDisruptionModalProps {
  isOpen: boolean
  onClose: () => void
}

const TemporaryServiceDisruptionModal: React.FC<
  TemporaryServiceDisruptionModalProps
> = ({ isOpen, onClose }) => {
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      title="Customer Notice from Penning Group: Changes to Our Stablecoin Offering"
    >
      <div className="flex flex-col items-center">
        <div className="text-description m-6 mt-0 space-y-3">
          <p>Dear Customer,</p>
          <p>
            We would like to inform you of an important change to our stablecoin
            offering for clients in the European Economic Area (EEA). Due to the
            new regulations coming into effect, we will no longer be able to
            support the following tokens:
          </p>
          <p className="font-semibold">
            By March 31, 2025, we will fully delist the following stablecoins
            for clients in the EEA:
            <br />
            USDT, PYSUD, EURT, TUSD, <span className="font-regular">and</span>{' '}
            UST.
          </p>
          <p className="font-semibold">What does this mean for you?</p>
          <p>Effective March 31, 2025, at 12:00 PM UTC</p>
          <ul>
            <li className="list-disc ml-6">
              You will no longer be able to place new orders for these assets.
            </li>
            <li className="list-disc ml-6">
              You will no longer be able to generate new deposit addresses for
              these stablecoins.
            </li>
          </ul>
          <p>
            <span className="font-semibold">What can you do? </span>
            We recommend considering alternative stablecoins from our supported
            list, such as <span className="font-semibold">USDC</span>, which
            will remain available on our platform.
          </p>
          <p>
            We understand that this may affect your trading, and we are here to
            assist you with any questions or support you may need during this
            transition. Please feel free to contact our customer support team
            for guidance.
          </p>
          <p>Thank you for your understanding and continued partnership.</p>
          <p>Best regards,</p>
          <p className="font-semibold">Penning Group</p>
          <p>Customer Support Team</p>

          <button className="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default TemporaryServiceDisruptionModal
