import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../assets/favicon.png'
import FormButton from '../../components/FormButton'
import {
  useMfaChallengeMutation,
  useMfaRememberQuery,
} from '../../redux/services/coreApi'
import { MfaChallengeCommand } from '../../types/coreApi-types'

// A custom hook that builds on useLocation to parse the query string
function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

interface MfaChallengeScreenProps {}

const MfaChallengeScreen: React.FC<MfaChallengeScreenProps> = () => {
  const query = useQuery()
  const [code, setCode] = useState('')
  const [isUsingRecoveryCode, setIsUsingRecoveryCode] = useState(false)
  const [rememberThisDevice, setRememberThisDevice] = useState(false)
  const { logout } = useAuth0()

  const { data: rememberData, isFetching: rememberIsLoading } =
    useMfaRememberQuery({
      sessionToken: query.get('session_token')!,
      state: query.get('state')!,
    })

  const [mfaChallenge, { isLoading: mfaChallengeIsLoading }] =
    useMfaChallengeMutation()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const state = query.get('state')!
    const sessionToken = query.get('session_token')!

    const mfaChallengeCommand: MfaChallengeCommand = {
      state,
      sessionToken,
      rememberThisDevice,
    }
    if (isUsingRecoveryCode) {
      mfaChallengeCommand.recoveryCode = code
    } else {
      mfaChallengeCommand.totpCode = code
    }

    const mfaChallengeResponse =
      await mfaChallenge(mfaChallengeCommand).unwrap()

    // biome-ignore lint/suspicious/noConsole: <explanation>
    console.log(mfaChallengeResponse)

    window.location.href = mfaChallengeResponse.redirectUrl
  }

  useEffect(() => {
    if (rememberData && rememberData.redirectUrl.length > 0) {
      window.location.href = rememberData.redirectUrl
    }
  }, [rememberData])

  let innerDiv: React.ReactNode

  if (rememberIsLoading || rememberData?.redirectUrl) {
    innerDiv = (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    )
  } else {
    innerDiv = (
      <>
        {/* Title / Subtitle */}
        <h1 className="text-xl font-bold text-center mb-1">
          Two-Factor Required
        </h1>
        <p className="text-sm text-gray-500 text-center mb-6">
          {isUsingRecoveryCode
            ? 'Please enter one of the 10 recovery codes you saved when setting up two-factor authentication'
            : 'Please enter the 6-digit code shown on your authenticator app to continue'}
        </p>

        {/* Form */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {isUsingRecoveryCode ? 'Recovery code' : '6-digit code'}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0B2C3F]"
              value={code}
              onChange={e => setCode(e.target.value)}
              autoComplete="off"
              required
            />
          </div>

          {/* Link/button to switch to using recovery code */}
          <div className="mb-4 text-left">
            <p
              className="text-sm text-blue-500 underline cursor-pointer"
              onClick={() => {
                setIsUsingRecoveryCode(!isUsingRecoveryCode)
              }}
            >
              {isUsingRecoveryCode ? 'Use TOTP code' : 'Use a recovery code'}
            </p>
          </div>

          <div
            className="flex items-center mb-4"
            onClick={() => {
              setRememberThisDevice(!rememberThisDevice)
            }}
          >
            <input
              className="appearance-none disabled:cursor-not-allowed h-4 w-4 mt-0.5 border border-gray-300 dark:border-brand-dark-card-border rounded-sm bg-white dark:bg-brand-dark-background checked:bg-brand-accent checked:border-brand-accent dark:checked:bg-brand-accent dark:checked:border-brand-accent focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              checked={rememberThisDevice}
              readOnly
            />
            <label className="text-description select-none pt-0.5 cursor-pointer">
              Remember this device for 30 days
            </label>
          </div>

          <FormButton title="Continue" isLoading={mfaChallengeIsLoading} />

          <button
            className="button-secondary"
            onClick={e => {
              e.preventDefault()
              logout()
            }}
          >
            Log out
          </button>
        </form>
      </>
    )
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#1D3A3C]">
      {/* The outer container with the dark background */}
      <div className="w-full max-w-sm mx-4 bg-white rounded-md shadow-lg p-8">
        {/* Logo or header section */}
        <div className="flex justify-center mb-6">
          <img src={logo} alt="Penning Logo" className="h-10" />
        </div>

        {innerDiv}
      </div>
    </div>
  )
}

export default MfaChallengeScreen
