import Navigator from './Navigator'
import './reset.css'
import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import { MantineProvider } from '@mantine/core'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import chakraTheme from './chakraTheme'
import {
  API_URL,
  AUTH0_CALLBACK_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} from './constants'
import RTKErrorLoggerHooksProvider from './containers/RTKErrorLoggerHooksProvider'
import mantineTheme from './mantineTheme'
import store from './redux/store'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { queryClient, wagmiAdapter } from './appkit'

function App() {
  useEffect(() => {
    if (localStorage.getItem('color-theme') === 'dark') {
      document.documentElement.classList.add('dark')
      document.body.setAttribute('theme', 'dark')

      document.body.classList.remove('html-light')
      document.body.classList.add('html-dark')
    } else {
      document.documentElement.classList.remove('dark')
      document.body.setAttribute('theme', 'light')
    }
  }, [])

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={AUTH0_CALLBACK_URL}
      audience={API_URL}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Provider store={store}>
        <ChakraProvider theme={chakraTheme} resetCSS={true}>
          <MantineProvider theme={mantineTheme}>
            <WagmiProvider config={wagmiAdapter.wagmiConfig}>
              <QueryClientProvider client={queryClient}>
                <RTKErrorLoggerHooksProvider>
                  <Navigator />
                </RTKErrorLoggerHooksProvider>
              </QueryClientProvider>
            </WagmiProvider>
          </MantineProvider>
        </ChakraProvider>
      </Provider>
    </Auth0Provider>
  )
}

export default App
