import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import AppNavigator from './AppNavigator'
import NotFoundScreen from './screens/App/screens/Not Found/NotFoundScreen'
import SwapInvoice from './screens/App/screens/SwapInvoice/SwapInvoice'
import ForgotPasswordScreen from './screens/Login/ForgotPasswordScreen'
import LoginScreen from './screens/Login/LoginScreen'
import SignUpScreen from './screens/Login/SignUpScreen'
import MfaChallengeScreen from './screens/Mfa/MfaChallengeScreen'

export default function App() {
  return (
    <div className="min-h-screen overflow-hidden">
      <Router>
        <Switch>
          <Route path="/app">
            <AppNavigator />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordScreen />
          </Route>
          <Route path="/sign-up">
            <SignUpScreen />
          </Route>
          <Route path="/mfa/challenge">
            <MfaChallengeScreen />
          </Route>
          <Route exact path="/">
            {/* Warning: LoginScreen (and everything not under AppNavigator) is
          not checked by AppAuthorizationChecker, meaning that the SessionState
          is not type-safe */}
            <LoginScreen />
          </Route>

          <Route path="/otac/swap-invoice">
            <SwapInvoice />
          </Route>

          <Route path="*">
            <NotFoundScreen />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}
