export const NODE_ENV = import.meta.env.VITE_NODE_ENV
export const SENTRY_DSN =
  import.meta.env.VITE_SENTRY_DSN ||
  'https://9a0cb1fbc220e3e2e7b0bc286d28abb2@o412076.ingest.sentry.io/4506353875681280'
export const API_URL =
  import.meta.env.VITE_API_URL ||
  'https://prod-penning-api-app-service.azurewebsites.net'

if (NODE_ENV === 'development') {
  // biome-ignore lint/suspicious/noConsole: <useful info>
  console.warn('*************** DEVELOPMENT ***************')
}
if (!SENTRY_DSN) {
  // biome-ignore lint/suspicious/noConsole: <useful info>
  console.warn('Warning: Sentry DSN not set!')
}
if (!API_URL) {
  // biome-ignore lint/suspicious/noConsole: <useful info>
  console.error('Error: No api url defined - the website will not work.')
} else if (API_URL.charAt(API_URL.length - 1) === '/') {
  // biome-ignore lint/suspicious/noConsole: <useful info>
  console.error(
    "Error: api url is incorrectly defined, it must not end with a '/'"
  )
}

export const DOMAIN =
  import.meta.env.VITE_DOMAIN || 'https://www.app.penning.dk'

export const BLOB_STORAGE_URL =
  import.meta.env.BLOB_STORAGE_URL ||
  'https://penningtempstorage.blob.core.windows.net'

// Auth0
export const AUTH0_DOMAIN =
  import.meta.env.VITE_AUTH0_DOMAIN || 'penning.eu.auth0.com'
export const AUTH0_CLIENT_ID =
  import.meta.env.VITE_AUTH0_CLIENT_ID || 'pdyOfd6UKlyVdcZfCY1BHZYAED8OVAvu'
export const AUTH0_CALLBACK_URL = `${DOMAIN}/app`
export const AUTH0_LOGOUT_URL = DOMAIN

// Misc.
export const ROUNDING_DIGITS_CRYPTO = 8
export const ROUNDING_DIGITS_FIAT = 2

export const DEFAULT_PAY_CURRENCY_ISO_CODE = 'EUR'
export const DEFAULT_RECEIVE_CURRENCY_ISO_CODE = 'EEUR'

export const VERSION = '1.1.3'

export const ARYZE_FEE_STRUCTURE_ID = 'EE3FDD0C-C856-4F5C-A5F7-78AD0723B9B0'

export const APPKIT_PROJECT_ID =
  import.meta.env.APPKIT_PROJECT_ID || '0d7b9f1fca93f261b2fc7ea803391ac9'
