import { Spinner, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { useHistory } from 'react-router-dom'
import penningLogo from '../../assets/icon-white.png'
import FormButton from '../../components/FormButton'
import {
  useMfaEnrollMutation,
  useMfaVerifyMutation,
} from '../../redux/services/coreApi'

interface MfaEnrollmentScreenProps {}

const MfaEnrollmentScreen: React.FC<MfaEnrollmentScreenProps> = () => {
  const history = useHistory()
  const [totpCode, setTotpCode] = useState('')
  const toast = useToast()

  const enrollMutexLock = useRef(false)

  const [enroll, { data: enrollData }] = useMfaEnrollMutation()
  const [verify, { data: verifyData, isLoading: verifyIsLoading }] =
    useMfaVerifyMutation()

  useEffect(() => {
    const startEnroll = async () => {
      if (enrollMutexLock.current) return

      enrollMutexLock.current = true
      await enroll()
      enrollMutexLock.current = false
    }
    startEnroll()
  }, [])

  const handleVerifySubmit = async (e: any) => {
    e.preventDefault()

    try {
      await verify({ totpCode }).unwrap()

      toast({
        title: 'Two-factor authentication enabled!',
        description: 'You can now securely log in to your account',
        status: 'success',
        duration: 5000,
      })
    } catch (err) {}
  }

  const handleRecoveryCodeSubmit = async (e: any) => {
    e.preventDefault()

    history.push('/app/account')
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-[500px]">
        <h2 className="text-2xl font-bold mb-4">
          Two-Factor Authentication Setup
        </h2>
        {enrollData || verifyData ? (
          verifyData ? (
            <div className="mt-4">
              <h3 className="font-bold mb-2">Recovery Codes</h3>
              <p className="text-sm mb-2">
                Save these codes in a secure location. They will be shown only
                once.
              </p>
              <ul className="list-disc list-inside bg-gray-50 p-2 rounded mb-2">
                {verifyData.recoveryCodes.map((code, idx) => (
                  <li key={idx} className="font-mono">
                    {code}
                  </li>
                ))}
              </ul>
              <form onSubmit={handleRecoveryCodeSubmit}>
                <button type="submit" className="button">
                  I have saved the codes in a secure location
                </button>
              </form>
            </div>
          ) : (
            <>
              <p className="mb-4">
                Scan the following QR code with your Authenticator app, e.g.
                Google Authenticator or similar
              </p>
              <div className="flex justify-center mb-4">
                <QRCode
                  value={enrollData!.otpAuthUrl}
                  size={200}
                  fgColor="#29383B"
                  bgColor="#FFF"
                  logoImage={penningLogo}
                />
              </div>
              <form onSubmit={handleVerifySubmit}>
                <label className="block mb-2">
                  Enter the code shown on your authenticator app:
                  <input
                    type="text"
                    value={totpCode}
                    onChange={e => setTotpCode(e.target.value)}
                    className="mt-1 p-2 border rounded w-full"
                    required
                  />
                </label>
                <FormButton title="Verify" isLoading={verifyIsLoading} />
              </form>
            </>
          )
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default MfaEnrollmentScreen
