import { Spinner, useDisclosure, useToast } from '@chakra-ui/react'
import { useAppKit, useAppKitAccount } from '@reown/appkit/react'
import React, { useEffect, useState } from 'react'
import { useSwitchChain } from 'wagmi'
import { networkIsoCodeToWagmiChainMapping } from '../../../../appkit'
import PageWrapper from '../../../../components/Pagewrapper'
import {
  useGetCurrenciesQuery,
  useGetNetworksQuery,
  useGetWalletsQuery,
} from '../../../../redux/services/coreApi'
import {
  CurrencyDto,
  NetworkDto,
  WalletDto,
} from '../../../../types/coreApi-types'
import CurrencyModal from '../Broker Service/components/CurrencyModal/CurrencyModal'
import {
  NetworkSelect,
  PayAndReceive,
  WalletSelect,
} from '../Broker Service/containers/CreateSwap'
import ThorChainSwapConfirmModal from './ThorChainSwapConfirmModal'
import {
  ThorChainCurrency,
  ThorChainNetwork,
  fetchThorChainPools,
  getThorChainQuote,
} from './thorChainUtils'

export interface ThorChainQuote {
  expected_amount_out: string
  fees: {
    total: string
  }
  memo: string
  inbound_address: string
  expiry: number
}

const ThorChainSwap: React.FC = () => {
  const toast = useToast()
  const [amount, setAmount] = useState<number>(Number.NaN)
  const [quote, setQuote] = useState<ThorChainQuote | null>(null)
  const [loadingPools, setLoadingPools] = useState(false)
  const [quoteLoading, setQuoteLoading] = useState(false)

  const { data: networksData } = useGetNetworksQuery()
  const { data: currenciesData } = useGetCurrenciesQuery()

  const [poolNetworks, setPoolNetworks] = useState<ThorChainNetwork[]>([])
  const [poolCurrencies, setPoolCurrencies] = useState<ThorChainCurrency[]>([])

  // Default currencies state
  const [payCurrency, setPayCurrency] = useState<
    ThorChainCurrency | undefined
  >()
  const [receiveCurrency, setReceiveCurrency] = useState<
    ThorChainCurrency | undefined
  >()

  // For network selection
  const [selectedPayNetwork, setSelectedPayNetwork] =
    useState<ThorChainNetwork | null>(null)
  const [selectedReceiveNetwork, setSelectedReceiveNetwork] =
    useState<ThorChainNetwork | null>(null)

  // Destination wallet state (managed via your WalletSelect component)
  const [destinationWallet, setDestinationWallet] = useState<WalletDto | null>(
    null
  )

  // currencyMode state for currency modal (to determine pay vs receive)
  const [currencyMode, setCurrencyMode] = useState<'pay' | 'receive'>('pay')

  const confirmSwapModal = useDisclosure()
  const currencyModal = useDisclosure()

  // AppKit account – connected wallet address (if any)
  const { address: connectedAddress } = useAppKitAccount()
  // Cast connectedAddress to the required template literal type if defined.
  const formattedAddress = connectedAddress
    ? (connectedAddress as `0x${string}`)
    : undefined

  // On mount, query the pools endpoint and filter for active pools.
  useEffect(() => {
    const fetchPools = async () => {
      if (!networksData || !currenciesData) return
      setLoadingPools(true)
      try {
        const { networks, cryptoCurrencies } = await fetchThorChainPools(
          networksData,
          currenciesData
        )

        setPoolNetworks(networks)
        setPoolCurrencies(cryptoCurrencies)

        if (cryptoCurrencies.length > 0) {
          setPayCurrency(cryptoCurrencies[0])
          setReceiveCurrency(cryptoCurrencies[1])
        }
      } catch (error: any) {
        toast({
          title: 'Error fetching pools',
          description: error.message,
          status: 'error',
          duration: 5000,
        })
      } finally {
        setLoadingPools(false)
      }
    }

    if (networksData && currenciesData) {
      fetchPools()
    }
  }, [toast, networksData, currenciesData])

  async function getQuote() {
    if (!amount || isNaN(amount) || amount <= 0) {
      toast({
        title: 'Enter a valid amount',
        status: 'warning',
        duration: 3000,
      })
      return
    }
    setQuoteLoading(true)
    setQuote(null)

    if (!destinationWallet) {
      toast({
        title: 'Select a destination wallet',
        status: 'warning',
        duration: 3000,
      })
      setQuoteLoading(false)
      return
    }

    if (!payCurrency || !receiveCurrency) {
      toast({
        title: 'Select pay and receive currencies',
        status: 'warning',
        duration: 3000,
      })
      setQuoteLoading(false)
      return
    }

    try {
      const data = await getThorChainQuote(
        destinationWallet.address,
        amount,
        payCurrency.thorChainAsset,
        receiveCurrency.thorChainAsset
      )
      setQuote(data)
      toast({ title: 'Quote received', status: 'success', duration: 3000 })
    } catch (error: any) {
      toast({
        title: 'Error fetching quote',
        description: error.message,
        status: 'error',
        duration: 5000,
      })
    } finally {
      setQuoteLoading(false)
    }
  }

  // Reset quote on changes
  useEffect(() => {
    if (quote !== null) setQuote(null)
  }, [
    payCurrency,
    receiveCurrency,
    selectedPayNetwork,
    selectedReceiveNetwork,
    amount,
  ])

  const { switchChainAsync } = useSwitchChain()

  const { open: openAppKit } = useAppKit()

  const { data: walletsData } = useGetWalletsQuery(null)

  // Determine if the swap action button should be active.
  const isSwapActionDisabled =
    (receiveCurrency?.currencyType === 'CryptoCurrency' &&
      !destinationWallet &&
      !formattedAddress) ||
    quoteLoading ||
    amount === 0

  const receiveAmount = quote?.expected_amount_out
    ? Number(quote.expected_amount_out) / 1e8
    : 0

  return (
    <PageWrapper
      title="ThorChain Swap"
      description="Swap assets on THORChain"
      isLoading={loadingPools}
    >
      <div>
        <div className="card max-w-[600px] flex-1 p-4">
          <div className="flex justify-between items-center">
            <p className="text-default-small">Connected wallet</p>
            <appkit-button></appkit-button>
          </div>

          <div className="h-px bg-brand-light-card-border dark:bg-brand-dark-card-border my-5 -mx-6"></div>

          <PayAndReceive
            isLoading={loadingPools}
            payAmount={amount}
            setPayAmount={v => setAmount(v)}
            receiveAmount={receiveAmount}
            setReceiveAmount={() => {}}
            payCurrency={payCurrency as CurrencyDto}
            receiveCurrency={receiveCurrency}
            currencyModal={currencyModal}
            isFetchingPayAmount={false}
            isFetchingReceiveAmount={false}
            quotationError={''}
            setCurrencyMode={setCurrencyMode}
          />

          <div className="space-y-1 mt-3">
            <div className="text-description flex justify-between">
              <span>Total fees</span>
              {quoteLoading ? (
                <Spinner size="xs" />
              ) : (
                <span className="text-brand-accent dark:text-white">
                  {quote?.fees.total ? Number(quote.fees.total) / 1e8 : 0}{' '}
                  {receiveCurrency?.isoCode}
                </span>
              )}
            </div>
          </div>

          <div className="h-px bg-brand-light-card-border dark:bg-brand-dark-card-border my-5 -mx-6"></div>

          <NetworkSelect
            label="Network of payment currency"
            currency={payCurrency}
            networksData={poolNetworks}
            // @ts-ignore <Type casting due to networksData being ThorChainNetwork[]>
            setSelectedNetwork={async (n: ThorChainNetwork) => {
              try {
                const wagmiChainId =
                  networkIsoCodeToWagmiChainMapping[n.isoCode ?? '']
                if (!wagmiChainId) {
                  throw new Error(
                    'This network is not supported with your wallet connector'
                  )
                }
                const switchedChain = await switchChainAsync({
                  chainId: wagmiChainId,
                })
                if (!switchedChain) {
                  throw new Error('Failed to switch chain')
                }

                setSelectedPayNetwork(n)
                const newPayCurrency = n.cryptoCurrencies.find(
                  c => c.isoCode === payCurrency?.isoCode
                )
                setPayCurrency(newPayCurrency as ThorChainCurrency)
              } catch (e: any) {
                toast({
                  title: 'Error',
                  description: e.message,
                  status: 'error',
                  duration: 5000,
                })
              }
            }}
            selectedNetwork={selectedPayNetwork}
            isCryptoCurrencyAndNetworkPairCompatible={(
              network: NetworkDto,
              currency?: CurrencyDto
            ) =>
              !!network.cryptoCurrencies.find(
                x => x.isoCode === currency?.isoCode
              )
            }
          />

          <NetworkSelect
            label="Network of receive currency"
            currency={receiveCurrency}
            networksData={poolNetworks}
            // @ts-ignore <Type casting due to networksData being ThorChainNetwork[]>
            setSelectedNetwork={(n: ThorChainNetwork) => {
              setSelectedReceiveNetwork(n)
              const newReceiveCurrency = n.cryptoCurrencies.find(
                c => c.isoCode === receiveCurrency?.isoCode
              )
              setReceiveCurrency(newReceiveCurrency as ThorChainCurrency)

              setDestinationWallet(null)
            }}
            selectedNetwork={selectedReceiveNetwork}
            isCryptoCurrencyAndNetworkPairCompatible={(
              network: NetworkDto,
              currency?: CurrencyDto
            ) =>
              !!network.cryptoCurrencies.find(
                x => x.isoCode === currency?.isoCode
              )
            }
          />

          <WalletSelect
            label="Destination wallet"
            currency={receiveCurrency}
            wallet={destinationWallet}
            setWallet={setDestinationWallet}
            isWalletAndCryptoCurrencyPairCompatible={(
              wallet: WalletDto,
              currency?: CurrencyDto
            ) => {
              if (!currency) return false
              return (
                wallet?.network?.isoCode === selectedReceiveNetwork?.isoCode
              )
            }}
            walletsData={walletsData}
            history={history}
          />

          {/* Action Button */}
          {!formattedAddress && !destinationWallet ? (
            <button
              className="button mt-8"
              onClick={() => {
                openAppKit()
              }}
            >
              Connect wallet
            </button>
          ) : (
            <button
              disabled={isSwapActionDisabled}
              className="button mt-8"
              onClick={() => {
                if (!quote) {
                  getQuote()
                } else {
                  confirmSwapModal.onOpen()
                }
              }}
            >
              {quoteLoading ? (
                <Spinner size="sm" />
              ) : !quote ? (
                'Receive quote'
              ) : (
                'Continue'
              )}
            </button>
          )}
        </div>
      </div>

      <CurrencyModal
        isOpen={currencyModal.isOpen}
        onClose={currencyModal.onClose}
        //@ts-ignore <ThorChainCurrency due to currenciesOverride prop being ThorChainCurrency[] type>
        onClickCurrency={(currency: ThorChainCurrency) => {
          if (currencyMode === 'pay') {
            setPayCurrency(currency)
            setSelectedPayNetwork(null)
          }
          if (currencyMode === 'receive') {
            setReceiveCurrency(currency)
            setSelectedReceiveNetwork(null)
            if (
              destinationWallet &&
              destinationWallet.network?.isoCode !==
                selectedReceiveNetwork?.isoCode
            ) {
              setDestinationWallet(null)
            }
          }
        }}
        networksOverride={poolNetworks}
        currenciesOverride={poolCurrencies}
        lockToCrypto
      />

      {quote &&
        payCurrency &&
        receiveCurrency &&
        formattedAddress &&
        destinationWallet &&
        selectedPayNetwork &&
        selectedReceiveNetwork && (
          <ThorChainSwapConfirmModal
            isOpen={confirmSwapModal.isOpen}
            onClose={confirmSwapModal.onClose}
            payAmount={amount}
            payCurrency={payCurrency}
            payAddress={formattedAddress}
            payNetwork={selectedPayNetwork}
            receiveAmount={receiveAmount}
            receiveCurrency={receiveCurrency}
            receiveAddress={destinationWallet.address}
            receiveNetwork={selectedReceiveNetwork}
            quoteMemo={quote.memo}
            quoteExpiry={quote.expiry}
            quoteInboundAddress={quote.inbound_address}
          />
        )}
    </PageWrapper>
  )
}

export default ThorChainSwap
