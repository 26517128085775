import { CurrencyDto, NetworkDto } from '../../../../types/coreApi-types'
import { ThorChainQuote } from './ThorChainSwap'

const thorChainUrl = 'https://thornode.ninerealms.com'
export interface Pool {
  asset: string
  status: string
}

type ThorChainAsset = {
  thorChainAsset: string
}
export type ThorChainNetwork = NetworkDto &
  ThorChainAsset & { cryptoCurrencies: ThorChainCurrency[] }
export type ThorChainCurrency = CurrencyDto & ThorChainAsset

const defaultCurrencyIcon =
  'https://static-00.iconduck.com/assets.00/generic-cryptocurrency-icon-2048x2029-vzaeox5w.png'

// Build and execute the swap quote call.
export const getThorChainQuote = async (
  destination: string,
  amount: number,
  fromAsset: string,
  toAsset: string
) => {
  const amountInBaseUnits = Math.floor(amount * 1e8)
  const url = `${thorChainUrl}/thorchain/quote/swap?from_asset=${fromAsset}&to_asset=${toAsset}&amount=${amountInBaseUnits}&destination=${destination}&streaming_interval=1&streaming_quantity=0`

  const res = await fetch(url, { cache: 'no-cache' })
  if (!res.ok) {
    const errorJson = await res.json()
    throw new Error(errorJson?.message)
  }
  const data = await res.json()

  return data as ThorChainQuote
}

export const fetchThorChainPools = async (
  networksData: NetworkDto[],
  currenciesData: CurrencyDto[]
) => {
  const res = await fetch(`${thorChainUrl}/thorchain/pools`)
  if (!res.ok) {
    throw new Error(`Error ${res.status}: ${res.statusText}`)
  }
  const data = await res.json()
  const activePools = data.filter((pool: Pool) => pool.status === 'Available')

  const networks: ThorChainNetwork[] = []
  for (const { asset, status } of activePools) {
    if (status !== 'Available') continue

    const assetSplit = asset.split('.')
    const assetNetwork = assetSplit[0]
    const assetIsoCode = assetSplit[1].split('-')[0]

    const svgIconUrl = `https://cryptofonts.com/img/SVG/${assetIsoCode.toLowerCase()}.svg`
    const svgIconResponse = await fetch(svgIconUrl)
    const iconUrl =
      svgIconResponse.status === 200 ? svgIconUrl : defaultCurrencyIcon

    const networkIconUrl =
      networksData?.find(n => n.isoCode === assetNetwork)?.iconUrl || iconUrl
    const currencyIconUrl =
      currenciesData?.find(c => c.isoCode === assetIsoCode)?.iconUrl || iconUrl

    const cryptoCurrency: ThorChainCurrency = {
      id: asset,
      name: assetIsoCode,
      isoCode: assetIsoCode,
      currencyType: 'CryptoCurrency',
      iconUrl: currencyIconUrl,
      thorChainAsset: asset,
      networks: [],
    }

    const foundNetwork = networks.find(n => n.isoCode === assetNetwork)
    if (!foundNetwork) {
      networks.push({
        id: asset,
        thorChainAsset: assetNetwork,
        name: assetNetwork,
        isoCode: assetNetwork,
        iconUrl: networkIconUrl,
        cryptoCurrencies: [cryptoCurrency],
      })
    } else {
      foundNetwork.cryptoCurrencies.push(cryptoCurrency)
    }
  }

  const cryptoCurrencies = networks
    .flatMap(n => n.cryptoCurrencies)
    .reduce((acc, curr) => {
      if (acc.find(x => x.isoCode === curr.isoCode)) return acc
      return [...acc, curr]
    }, [] as ThorChainCurrency[])

  return {
    networks,
    cryptoCurrencies,
  }
}
