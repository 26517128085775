import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/react'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import FormButton from '../../../../components/FormButton'
import PageWrapper from '../../../../components/Pagewrapper'
import Toast from '../../../../components/Toast'
import Tooltip from '../../../../components/Tooltip'
import {
  coreApi,
  useCreateKycCaseMutation,
  useCreateQuestionnaireMutation,
  useGetMembersQuery,
  useGetUserQuery,
  useResendVerificationEmailMutation,
} from '../../../../redux/services/coreApi'
import type { RootState } from '../../../../redux/store'
import Cancelled from './components/Cancelled'
import Completed from './components/Completed'
import Step from './components/Step'

type GetStartedScreenProps = {}

const GetStartedScreen: React.FC<GetStartedScreenProps> = () => {
  const { path } = useRouteMatch()
  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  const sessionUser = useSelector((state: RootState) => state.session.user)
  const [pollingInterval, setPollingInterval] = useState(10000) // Start with polling
  const { data: user } = useGetUserQuery(sessionUser.id, {
    pollingInterval,
  })

  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(
    user?.emailVerified ?? false
  )

  // Update polling and email verification status when user data changes
  useEffect(() => {
    if (user?.emailVerified) {
      setIsEmailVerified(true)
      setPollingInterval(0) // Stop polling when verified
    }
  }, [user?.emailVerified])

  const { getIdTokenClaims } = useAuth0()

  const [resendVerificationEmail, { isLoading: isResendingVerificationEmail }] =
    useResendVerificationEmailMutation()

  const [createQuestionaire, { isLoading: isCreatingQuestionnaire }] =
    useCreateQuestionnaireMutation()

  const [createKycCase, { isLoading: isCreatingKycCase }] =
    useCreateKycCaseMutation()

  const { data: members, refetch: refetchMembers } = useGetMembersQuery(null, {
    skip: sessionUser.role !== 'Organization',
  })

  const isOrganization = user?.role === 'Organization'
  const kycStatus = user?.kycDetails
  const [hasSentMail, setHasResendMail] = useState(false)

  if (kycStatus?.kycStatus === 'Declined') {
    return <Cancelled />
  }

  if (
    kycStatus?.kycStatus === 'Approved' &&
    (isOrganization ? kycStatus.questionnaireStatus === 'Completed' : true)
  ) {
    return <Completed />
  }

  let organizationPartialUBOFillOut = false
  if (
    members &&
    members.find(x => x.kycDetails?.kycStatus !== 'Pending') &&
    members.find(x => x.kycDetails?.kycStatus === 'Pending')
  ) {
    organizationPartialUBOFillOut = true
  }

  const stepStateForAML =
    (kycStatus?.questionnaireStatus === 'Pending' ||
      kycStatus?.questionnaireStatus === 'Processing') &&
    kycStatus.kycStatus === 'Completed' &&
    isEmailVerified
      ? 'in-progress'
      : kycStatus?.questionnaireStatus === 'Completed'
        ? 'completed'
        : 'pending'

  function getRemainingHoursLeft(createdAt?: string | null) {
    const expiryMomentUtc = moment.utc(createdAt).add(48, 'h')
    const momentUtc = moment.utc()

    return expiryMomentUtc.diff(momentUtc, 'h')
  }

  const remainingHoursLeftKyc = getRemainingHoursLeft(
    kycStatus?.shuftiProKycCreatedAt
  )
  const remainingHoursLeftQuestionnaire = getRemainingHoursLeft(
    kycStatus?.shuftiProQuestionnaireCreatedAt
  )

  return (
    <PageWrapper fixedWidth>
      <div className="card gap-2 md:gap-4 mt-20 relative">
        <button
          id="testBtn"
          onClick={async () => {
            const testBtn = document.getElementById('testBtn')
            testBtn?.focus()
            setTimeout(() => {
              testBtn?.blur()
            }, 600)

            if (!isEmailVerified) {
              //@ts-ignore
              window.location = window.location
            } else {
              dispatch(
                coreApi.util.invalidateTags(['User', 'Users', 'Members'])
              )
            }
          }}
          className="transition-all focus:rotate-[360deg] duration-500 ease-out absolute right-7 top-7"
        >
          <Tooltip label={`Update`} closeOnClick>
            <FontAwesomeIcon icon={faArrowsRotate} />
          </Tooltip>
        </button>
        <div className="text-center mt-5">
          <h1 className="font-semi text-lg">Welcome to Penning! </h1>
          <p className="text-description">
            Complete these simple steps to get your account up and running.
          </p>
        </div>
        <div className="space-y-5 mt-10">
          <Step
            status="completed"
            step={1}
            title="Create your account"
            description="Take your first step towards becoming a Penning investor."
          />
          <Step
            status={!isEmailVerified ? 'in-progress' : 'completed'}
            step={2}
            title="Verify your email"
            description="We have sent you an email. This helps us know that you own this account."
          >
            <FormButton
              disabled={hasSentMail}
              isLoading={isResendingVerificationEmail}
              loadingText="Sending.."
              title="Resend email"
              onClick={async () => {
                await resendVerificationEmail(null).unwrap()
                setHasResendMail(true)
                toast({
                  position: 'top-right',
                  render: () => {
                    return (
                      <Toast type="success">
                        We have sent you a new link to {sessionUser?.email}{' '}
                        verify your email.
                      </Toast>
                    )
                  },
                })
              }}
            />
          </Step>

          {isOrganization ? (
            <>
              <Step
                step={3}
                status={
                  kycStatus?.kycStatus === 'Pending' && isEmailVerified
                    ? 'in-progress'
                    : kycStatus?.kycStatus !== 'Pending'
                      ? 'completed'
                      : 'pending'
                }
                title={
                  organizationPartialUBOFillOut
                    ? 'Send KYC Requests to all your Members'
                    : 'List Ultimate Beneficial owners'
                }
                description="Establish the identity and ownership of your company."
              >
                <FormButton
                  title={
                    organizationPartialUBOFillOut ? 'Go to Profile' : 'Start'
                  }
                  onClick={() => {
                    if (organizationPartialUBOFillOut) {
                      history.push(`/app/account`)
                    } else {
                      history.push(`/app/kyc/start`)
                    }
                  }}
                />
              </Step>

              <Step
                step={4}
                status={
                  kycStatus?.kycStatus === 'Processing' && isEmailVerified
                    ? 'in-progress'
                    : kycStatus?.kycStatus === 'Completed'
                      ? 'completed'
                      : 'pending'
                }
                title="KYC of all Ultimate Beneficial owners"
                description="All of your Members must complete their KYC Process before you can continue. "
              >
                <FormButton
                  title="Track progress"
                  onClick={() => history.push(`/app/account`)}
                />
              </Step>

              <Step
                step={5}
                status={
                  (kycStatus?.questionnaireStatus === 'Pending' ||
                    kycStatus?.questionnaireStatus === 'Processing') &&
                  kycStatus.kycStatus === 'Completed' &&
                  isEmailVerified
                    ? 'in-progress'
                    : kycStatus?.questionnaireStatus === 'Completed'
                      ? 'completed'
                      : 'pending'
                }
                title="Fill out AML questionnaire"
                description={
                  <>
                    <p>
                      We do this to fight and prevent money-related financial
                      crimes.
                    </p>
                    {stepStateForAML === 'in-progress' && (
                      <>
                        <p className="mt-2">
                          Here you need various documents that document your
                          address, group of owners, management etc. including:{' '}
                        </p>
                        <ul className="list-disc ml-4 mt-2">
                          <li>Company diagram</li>
                          <li>Articles of association</li>
                          <li>Incorporation documents</li>
                          <li>Bank statements</li>
                        </ul>
                      </>
                    )}
                  </>
                }
                // "We do this to fight and prevent money-related financial crimes."
              >
                <FormButton
                  title={
                    kycStatus?.shuftiProQuestionnaireUrl &&
                    remainingHoursLeftQuestionnaire > 0
                      ? `Continue (${remainingHoursLeftQuestionnaire} hours left)`
                      : 'Start'
                  }
                  isLoading={isCreatingQuestionnaire}
                  loadingText="Loading.."
                  onClick={async () => {
                    if (
                      kycStatus?.shuftiProQuestionnaireUrl &&
                      remainingHoursLeftQuestionnaire > 0
                    ) {
                      if (kycStatus?.shuftiProQuestionnaireUrl) {
                        window.open(
                          kycStatus?.shuftiProQuestionnaireUrl,
                          '_blank'
                        )
                      }
                    } else {
                      const res = await createQuestionaire({}).unwrap()

                      if (res.shuftiProQuestionnaireUrl) {
                        window.open(res.shuftiProQuestionnaireUrl, '_blank')
                      }
                    }
                  }}
                />
              </Step>
            </>
          ) : (
            <>
              <Step
                step={3}
                status={
                  (kycStatus?.kycStatus === 'Pending' ||
                    kycStatus?.kycStatus === 'Processing') &&
                  isEmailVerified
                    ? 'in-progress'
                    : kycStatus?.kycStatus === 'Completed'
                      ? 'completed'
                      : 'pending'
                }
                title={`${
                  kycStatus?.kycStatus === 'Pending' ? 'Start' : 'Continue'
                } your KYC process`}
                description="We need to identify and verify your identity before you can use Penning."
              >
                <FormButton
                  title={
                    kycStatus?.shuftiProKycUrl && remainingHoursLeftKyc > 0
                      ? `Continue (${remainingHoursLeftKyc} hours left)`
                      : 'Start'
                  }
                  isLoading={isCreatingKycCase}
                  onClick={async () => {
                    if (
                      kycStatus?.shuftiProKycUrl &&
                      remainingHoursLeftKyc > 0
                    ) {
                      window.open(kycStatus?.shuftiProKycUrl)
                    } else {
                      const res = await createKycCase({}).unwrap()

                      if (res.shuftiProKycUrl) {
                        window.open(res.shuftiProKycUrl)
                      }
                    }
                  }}
                />
              </Step>
            </>
          )}

          <Step
            step={isOrganization ? 6 : 4}
            status={
              kycStatus?.kycStatus === 'Completed' &&
              isEmailVerified &&
              (isOrganization
                ? kycStatus.questionnaireStatus === 'Completed'
                : true)
                ? 'in-progress'
                : 'pending'
            }
            title="Waiting for manual approval"
            description="We are in the process of handling your questionnaire. Average wait time: 1 business day."
          />
        </div>
        <div className="h-[1px] bg-black/5 -mx-6" />
        <div className="flex flex-col items-center p-5">
          <p className="font-medium mb-2">Experiencing any problems?</p>
          <span className="text-description">
            Write us on{' '}
            <a
              href="mailto:m@penning.dk"
              className="hover:underline text-brand-blue"
            >
              j@penning.dk
            </a>{' '}
            or call us on{' '}
            <a
              href="tel:+4520650950"
              className="hover:underline text-brand-blue"
            >
              +45 20 65 09 50
            </a>{' '}
            .
          </span>
        </div>
      </div>
    </PageWrapper>
  )
}

export default GetStartedScreen
