import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import {
  AppKitNetwork,
  avalanche,
  bitcoin,
  bsc,
  dogechain,
  mainnet,
  polygon,
  sepolia,
} from '@reown/appkit/networks'
import { createAppKit } from '@reown/appkit/react'
import { QueryClient } from '@tanstack/react-query'
import { APPKIT_PROJECT_ID, DOMAIN } from './constants'

export const queryClient = new QueryClient()

const metadata = {
  name: 'Penning',
  description: 'Penning AppKit',
  url: DOMAIN, // origin must match your domain & subdomain
  icons: ['https://www.app.penning.dk/favicon.ico'],
}

const networks: [AppKitNetwork, ...AppKitNetwork[]] = [
  mainnet,
  bsc,
  polygon,
  avalanche,
  bitcoin,
  dogechain,
  sepolia,
]

export const networkIsoCodeToWagmiChainMapping: Record<string, number> = {
  ETH: mainnet.id,
  // ETH: sepolia.id,
  BSC: bsc.id,
  MATIC: polygon.id,
  AVAX: avalanche.id,
  DOGE: dogechain.id,
  SEPOLIA: sepolia.id,
}

export const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId: APPKIT_PROJECT_ID,
  ssr: true,
})

// 5. Create modal
createAppKit({
  adapters: [wagmiAdapter],
  networks,
  projectId: APPKIT_PROJECT_ID,
  metadata,
  features: {
    analytics: false,
    swaps: false,
    onramp: false,
  },
  themeMode: 'light',
})
