import { Spinner, useToast } from '@chakra-ui/react'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { skipToken } from '@reduxjs/toolkit/query'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import CDropdownMenu from '../../../../../../components/CDropdownMenu'
import DefaultModal from '../../../../../../components/DefaultModal'
import DropdownCell from '../../../../../../components/DropdownCell'
import InfoBox from '../../../../../../components/InfoBox'
import Toast from '../../../../../../components/Toast'
import {
  useGetExchangeRatePairQuery,
  useGetNetworksQuery,
  useGetWalletsQuery,
  useUpdateCurrencyMutation,
} from '../../../../../../redux/services/coreApi'
import type {
  CurrencyToOffRampDto,
  NetworkOffRampDto,
} from '../../../../../../types/coreApi-types'
import ImageAndValue from './ImageAndValue'

interface CurrenciesScreenProps {
  isOpen: boolean
  onClose: () => void
  currency?: CurrencyToOffRampDto | null
}

const CurrencyOverviewDetailsModal: React.FC<CurrenciesScreenProps> = ({
  isOpen,
  onClose,
  currency,
}) => {
  const toast = useToast()

  const [active, setActive] = useState(false)
  const [updateCurrency, { isLoading: updateCurrencyIsLoading }] =
    useUpdateCurrencyMutation()
  const {
    data: exchangeRateData,
    isFetching: exchangeRateFetching,
    isError: exchangeRateIsError,
  } = useGetExchangeRatePairQuery(
    currency
      ? {
          currencyFromIsoCode: currency?.isoCode,
          currencyToIsoCode: 'EUR',
        }
      : skipToken,
    { skip: !currency }
  )
  const { data: networks } = useGetNetworksQuery()
  const { data: wallets } = useGetWalletsQuery(null)

  const [activeNetworks, setActiveNetworks] = useState<
    { id: string; name: string }[]
  >([])
  const [activeWallets, setActiveWallets] = useState<
    { id: string; address: string; network: NetworkOffRampDto }[]
  >([])

  useEffect(() => {
    if (currency) {
      setActive((currency.active as unknown) === 'Active' ? true : false)
      setActiveNetworks(currency.networks || [])
      setActiveWallets(currency.supportedSystemWallets || [])
    }
  }, [currency, setActiveNetworks])

  return (
    <DefaultModal
      isLoading={false}
      isOpen={isOpen}
      onClose={onClose}
      whiteBG
      maxW="500px"
    >
      <div className="p-5 text-description-large">
        <p className="text-xl">Currency overview</p>

        <div className="pt-5 text-description">
          {exchangeRateFetching ? (
            <Spinner />
          ) : exchangeRateIsError ? (
            <p>
              <span>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="text-base mr-2"
                />
              </span>
              Exchange rate error!
            </p>
          ) : (
            <p>
              Current rate: 1 {currency?.isoCode} &#8776;{' '}
              {exchangeRateData?.exchangeRate}
              &euro;
            </p>
          )}
        </div>

        <div className="mt-10 bg-brand-gray-100 border-[1px] border-solid border-brand-gray-200 px-4 py-3 rounded-lg flex justify-between">
          <ImageAndValue
            value={currency?.name ?? ''}
            iconUrl={currency?.iconUrl}
          />
          <div className="flex items-center gap-3">
            {active ? (
              <InfoBox colorScheme="green">Active</InfoBox>
            ) : (
              <InfoBox colorScheme="red">Inactive</InfoBox>
            )}
          </div>
        </div>
        <button
          className="mt-3 button"
          onClick={async () => {
            if (!currency) return
            setActive(!active)
          }}
        >
          {updateCurrencyIsLoading ? (
            <Spinner size="xs" />
          ) : (
            'Change status'
          )}{' '}
        </button>

        {currency?.currencyType === 'CryptoCurrency' && (
          <>
            <div className="mt-10 mb-4 card">
              {activeNetworks.map(network => (
                <div
                  key={network.id}
                  className="flex justify-between items-center"
                >
                  <p className="text-description">{network.name}</p>
                  <p
                    className="text-description cursor-pointer p-2"
                    onClick={() => {
                      const existingActiveNetworkIndex =
                        activeNetworks.findIndex(x => x.id === network.id)

                      if (existingActiveNetworkIndex !== -1) {
                        setActiveNetworks(networks => {
                          const temp = [...networks]
                          temp.splice(existingActiveNetworkIndex, 1)
                          return temp
                        })
                      }
                    }}
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
            <CDropdownMenu
              emptyText="Select active networks"
              label="Active networks"
              selectedIconSrc={null}
              selectedText={undefined}
              renderList={networks?.map(network => {
                return (
                  <DropdownCell
                    key={network.id}
                    title={network.name}
                    subtitle={network.nativeCurrency?.isoCode}
                    onClick={() => {
                      const existingActiveNetworkIndex =
                        activeNetworks.findIndex(x => x.id === network.id)
                      if (existingActiveNetworkIndex !== -1) {
                        setActiveNetworks(networks => {
                          const temp = [...networks]
                          temp.splice(existingActiveNetworkIndex, 1)
                          return temp
                        })
                      } else {
                        setActiveNetworks(networks => [...networks, network])
                      }
                    }}
                    isActive={false}
                    imageName={network}
                  />
                )
              })}
            />

            <div className="mt-10 mb-4 card">
              {activeWallets.map(wallet => (
                <div
                  key={wallet.id}
                  className="flex justify-between items-center"
                >
                  <p className="text-description">{wallet.address}</p>
                  <p
                    className="text-description cursor-pointer p-2"
                    onClick={() => {
                      const existingActiveWalletIndex = activeWallets.findIndex(
                        x => x.id === wallet.id
                      )
                      if (existingActiveWalletIndex !== -1) {
                        setActiveWallets(wallets => {
                          const temp = [...wallets]
                          temp.splice(existingActiveWalletIndex, 1)
                          return temp
                        })
                      }
                    }}
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
            <CDropdownMenu
              emptyText="Select wallets"
              label="Wallets"
              selectedIconSrc={null}
              selectedText={undefined}
              renderList={[...(wallets ?? [])]
                ?.sort((a, b) => {
                  const aDisabled = !activeNetworks.find(
                    x => x.id === a.network.id
                  )
                  const bDisabled = !activeNetworks.find(
                    x => x.id === b.network.id
                  )
                  if (aDisabled && !bDisabled) return 1
                  else if (!aDisabled && bDisabled) return -1
                  else return 0
                })
                .map(wallet => {
                  return (
                    <DropdownCell
                      key={wallet.id}
                      disabled={
                        !activeNetworks.find(x => x.id === wallet.network.id)
                      }
                      title={wallet.address}
                      subtitle={wallet.network.name}
                      onClick={() => {
                        const existingActiveWalletIndex =
                          activeWallets.findIndex(x => x.id === wallet.id)
                        if (existingActiveWalletIndex !== -1) {
                          setActiveWallets(wallets => {
                            const temp = [...wallets]
                            temp.splice(existingActiveWalletIndex, 1)
                            return temp
                          })
                        } else {
                          setActiveWallets(wallets => [...wallets, wallet])
                        }
                      }}
                      isActive={false}
                    />
                  )
                })}
            />
          </>
        )}

        <div className="flex gap-2 w-full mt-16">
          <button className="button-secondary" onClick={onClose}>
            Close
          </button>
          <button
            className="button"
            disabled={
              currency?.currencyType === 'CryptoCurrency' &&
              (activeNetworks.length === 0 ||
                activeWallets.length === 0 ||
                activeNetworks.length !== activeWallets.length)
            }
            onClick={async () => {
              onClose()
              if (!currency) return
              try {
                await updateCurrency({
                  id: currency.id,
                  body: {
                    active,
                    networkIds: activeNetworks.map(x => x.id),
                    supportedSystemWalletIds: activeWallets.map(x => x.id),
                  },
                }).unwrap()
                toast({
                  position: 'top',
                  render: () => {
                    return (
                      <Toast type="success">
                        The status was successfully changed.
                      </Toast>
                    )
                  },
                })
              } catch (e) {
                captureException(e)
              }
            }}
          >
            {updateCurrencyIsLoading ? (
              <Spinner size="xs" />
            ) : (
              'Confirm change(s)'
            )}{' '}
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default CurrencyOverviewDetailsModal
