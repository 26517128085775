import { useToast } from '@chakra-ui/react'
import {
  faClock,
  faShieldHalved,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSendTransaction } from 'wagmi'
import AnimateHeight from '../../../../components/AnimateHeight'
import DefaultModal from '../../../../components/DefaultModal'
import FormCheckbox from '../../../../components/FormCheckbox'
import InfoBox from '../../../../components/InfoBox'
import { CurrencyDto, NetworkDto } from '../../../../types/coreApi-types'

interface ThorChainSwapConfirmModalProps {
  isOpen: boolean
  onClose: () => void
  payAmount: number
  payCurrency: CurrencyDto
  payAddress: string
  payNetwork: NetworkDto
  receiveAmount: number
  receiveCurrency: CurrencyDto
  receiveAddress: string
  receiveNetwork: NetworkDto
  quoteMemo: string
  quoteExpiry: number
  quoteInboundAddress: string
}

const ThorChainSwapConfirmModal: React.FC<ThorChainSwapConfirmModalProps> = ({
  isOpen,
  onClose,
  payAmount,
  payCurrency,
  payAddress,
  payNetwork,
  receiveAmount,
  receiveCurrency,
  receiveAddress,
  receiveNetwork,
  quoteMemo,
  quoteExpiry,
  quoteInboundAddress,
}) => {
  const toast = useToast()
  const [confirmChecked, setConfirmChecked] = useState<boolean>(false)
  const [confirmError, setConfirmError] = useState<string>('')

  // Reset checkbox state when modal closes.
  useEffect(() => {
    if (!isOpen) {
      setConfirmChecked(false)
      setConfirmError('')
    }
  }, [isOpen])

  // Use wagmi's useSendTransaction hook.
  const { sendTransaction, data, status, error } = useSendTransaction()

  useEffect(() => {
    if (status === 'success') {
      toast({
        title: 'Transaction Sent!',
        description: data,
        status: 'success',
        duration: 5000,
      })
    } else if (status === 'error') {
      toast({
        title: 'Swap Execution Failed',
        description: error?.message,
        status: 'error',
        duration: 5000,
      })
    }
  }, [data, status, error])

  // Helper: extract chain from asset notation.
  const getChainFromAsset = (asset: string) => asset.split('.')[0]

  const executeSwap = async () => {
    if (!quoteMemo) {
      toast({
        title: 'No quote available',
        status: 'warning',
        duration: 3000,
      })
      return
    }
    // Only support native ETH (or BSC) swaps.
    // const chain = getChainFromAsset(payCurrency.id)
    // if (chain !== 'ETH' && chain !== 'BSC') {
    //   toast({
    //     title: 'Swap execution not supported',
    //     description:
    //       'Execution via MetaMask is only supported for native ETH swaps. For ERC-20 tokens or non-EVM assets, a router integration is required.',
    //     status: 'warning',
    //     duration: 5000,
    //   })
    //   return
    // }
    // if (!(window as any).ethereum) {
    //   toast({
    //     title: 'MetaMask not detected',
    //     status: 'error',
    //     duration: 3000,
    //   })
    //   return
    // }

    try {
      // Convert the payAmount (assumed in ETH) to wei as bigint.
      const valueInWei = BigInt(Math.floor(payAmount * 1e18))
      // Encode the quote memo to hex using TextEncoder.
      const encoder = new TextEncoder()
      const memoBytes = encoder.encode(quoteMemo)
      const memoHex =
        '0x' +
        Array.from(memoBytes)
          .map(b => b.toString(16).padStart(2, '0'))
          .join('')

      // Send the transaction. Note: we pass the parameters directly.
      sendTransaction({
        to: quoteInboundAddress as any,
        value: valueInWei,
        data: memoHex as any,
      })
      // For waiting confirmation, consider using wagmi's useWaitForTransaction.
    } catch (err: any) {
      toast({
        title: 'Swap Execution Failed',
        description: err.message,
        status: 'error',
        duration: 5000,
      })
    }
  }

  const quoteExpiryMoment = moment(quoteExpiry * 1000)

  return (
    <DefaultModal
      isLoading={false}
      isLoadingText="Generating swap..."
      isOpen={isOpen}
      onClose={onClose}
      title="THORChain Swap Confirmation"
      maxW="700px"
    >
      <div className="p-5">
        <h2 className="text-default-small mb-2">Payment method</h2>
        <div className="bg-brand-light-card dark:bg-brand-dark-card grid grid-cols-10 justify-between p-5 rounded-md">
          <div className="col-span-6">
            <p className="text-default flex items-center gap-2">
              <img src={payNetwork?.iconUrl} className="inline-icon" />
              {payAddress}
            </p>
            <p className="text-description mt-1">Address</p>
          </div>
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <img src={payNetwork?.iconUrl} className="inline-icon" />
              <p className="text-default">{payNetwork.name}</p>
            </div>
            <p className="text-description mt-1">Network</p>
          </div>
        </div>

        <h2 className="mt-4 text-default-small mb-2">Receive method</h2>
        <div className="bg-brand-light-card dark:bg-brand-dark-card grid grid-cols-10 justify-between p-5 rounded-md">
          <div className="col-span-6">
            <p className="text-default flex items-center gap-2">
              <img src={receiveNetwork.iconUrl} className="inline-icon" />
              {receiveAddress}
            </p>
            <p className="text-description mt-1">Address</p>
          </div>
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <img src={receiveNetwork.iconUrl} className="inline-icon" />
              <p className="text-default">{receiveNetwork.name}</p>
            </div>
            <p className="text-description mt-1">Network</p>
          </div>
        </div>

        <h2 className="mt-4 text-default-small mb-2">Expected swap result</h2>
        <div className="bg-brand-light-card dark:bg-brand-dark-card grid grid-cols-10 justify-between p-5 rounded-md">
          <div className="col-span-6">
            <p className="text-default flex items-center gap-2">
              <img src={payCurrency.iconUrl} className="inline-icon" />
              {payAmount}
            </p>
            <p className="text-description mt-1">Pay amount</p>
          </div>
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <img src={receiveCurrency.iconUrl} className="inline-icon" />
              <p className="text-default">{receiveAmount}</p>
            </div>
            <p className="text-description mt-1">Receive amount</p>
          </div>
        </div>

        <div className="my-10">
          <div className="flex">
            <div className="w-[36px] text-brand-accent flex-shrink-0">
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div>
              <p className="text-default mb-1">
                This pricing quote is only valid untill{' '}
                {quoteExpiryMoment.format('HH:mm')} today.
              </p>
              <p className="text-description">
                If you continue with the swap after this time, the quote is
                invalid and you will not be guaranteed to receive anything.
              </p>
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-[36px] text-brand-accent flex-shrink-0">
              <FontAwesomeIcon icon={faShieldHalved} />
            </div>
            <div>
              <p className="text-default mb-1">
                THORChain is a third-party provider
              </p>
              <p className="text-description">
                Ensure that the above information is correct and that you are
                able to receive the currency with the selected Receive method.
                The transaction will be executed using THORChain Swap and thus
                if funds are sent incorrectly they will be permanently lost. We
                are not responsible for any lost funds using this method.
              </p>
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-[36px] text-brand-accent flex-shrink-0">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div>
              <p className="text-default mb-1">This is a beta feature</p>
              <p className="text-description">
                Please double-check all details before proceeding, and check
                everything in your wallet connector with regards to what network
                you are connected to and are signing the transaction for. This
                feature should not be trusted with large amounts of funds until
                it has been thoroughly tested.
              </p>
            </div>
          </div>
        </div>

        <FormCheckbox
          renderLabel="I confirm that the details above are correct, and that I am the sole responsible party for the transaction, and I understand that the transaction cannot be reverted."
          onChange={() => {
            if (confirmError && !confirmChecked) {
              setConfirmError('')
            }
            setConfirmChecked(!confirmChecked)
          }}
          checked={confirmChecked}
          error={{ message: confirmError }}
        />

        <button
          className="button mt-6"
          disabled={!confirmChecked}
          onClick={async () => {
            if (!confirmChecked) {
              setConfirmError('Required')
              return
            }
            await executeSwap()
          }}
        >
          Execute swap
        </button>

        {status === 'success' && (
          <AnimateHeight isVisible={status === 'success'}>
            <InfoBox className="w-min mt-4" colorScheme="green">
              <div className="flex flex-col gap-2">
                <p>Transaction successfully submitted!</p>
                <p className="font-semibold">
                  Transaction hash: <span className="font-regular">{data}</span>
                </p>
              </div>
            </InfoBox>
          </AnimateHeight>
        )}
      </div>
    </DefaultModal>
  )
}

export default ThorChainSwapConfirmModal
